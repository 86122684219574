import { gql } from "@apollo/client";

// This query is used to get recommendation result
export const GETRECOMMENDATION = gql`
  query getRecommendation($answers: UserRecoInput) {
    getRecommendation(answers: $answers) {
      name
      recommendations {
        content
        products
        food {
          avoid
          eat {
            foods
            lookup
            nsoverlap
            ingredient
            spanish_ingredient
          }
        }
        reason
      }
    }
  }
`;

// export const GETTESTSCENARIOSLIST = gql`
//   query MyQuery($answers: AWSJSON) {
//    listTestScenario($answers: $answers) {
//     test_id
//     user
//     version
//   }
//   }
// `;

// This query is used to create user
export const CREATEUSER = gql`
  mutation createUser($userinfo: UserInfo) {
    createUser(userinfo: $userinfo) {
      status
      accountId
      accountName
      accountSubType
      country
      partyId
      salesPlanAff
      language
      aboNumber
      userEmail
      recoUserId
      userConsent
    }
  }
`;

// This query is used to update user reco id if existing

export const UPDATEUSERRECOID = gql`
  mutation updateUserRecoId($recoIdData: RecoIdData) {
    updateRecoUserId(recoIdData: $recoIdData) {
      message
      status
    }
  }
`;

// This query is used to update user reco id if existing

export const GETRECOMMENDATIONBYRECOID = gql`
  query MyQuery($recoId: String!) {
    getRecommendationById(recoId: $recoId) {
      name
      recommendations {
        content
        food {
          avoid
          eat {
            foods
            nsoverlap
            lookup
            ingredient
            spanish_ingredient
          }
        }
        products
        reason
      }
    }
  }
`;

// This query is used to update user
export const UPDATEUSER = gql`
  mutation updateUser($userinfo: UserInfo) {
    updateUser(userinfo: $userinfo) {
      message
      status
    }
  }
`;
