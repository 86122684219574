//this function sets save result popup time on result page
export function setGuestSaveResultTime() {
  let currentTime = Math.round(new Date().getTime() / 1000) + 10;
  sessionStorage.setItem("guestSaveResultTime", JSON.stringify(currentTime));
}

//this function compares current time with actual save result popu time
export function compareExpiryTime() {
  let currentTime = Math.round(new Date().getTime() / 1000);
  let diffTime: any = "";
  if (sessionStorage.getItem("guestSaveResultTime")) {
    diffTime =
      parseInt(sessionStorage.getItem("guestSaveResultTime") || "") -
      currentTime;
  }
  return diffTime;
}

//this function set expiry time for guest user
export function setGuestExpiryTime() {
  let currentTime = Math.round(new Date().getTime() / 1000) + 3600;
  localStorage.setItem("guestExpiryTime", JSON.stringify(currentTime));
}

//this function compares current time with actual guest expiry time
export function compareGuestExpiryTime() {
  let currentTime = Math.round(new Date().getTime() / 1000);
  let diffTime: any = "";
  if (localStorage.getItem("guestExpiryTime")) {
    diffTime =
      parseInt(localStorage.getItem("guestExpiryTime") || "") - currentTime;
  }
  return diffTime;
}
