import { gql } from "@apollo/client";

export const GETMETARECOMMENDATION = gql`
  query getMetaRecommendation($answers: AWSJSON) {
    getMetaRecommendation(answers: $answers) {
      metadata {
        aggregated_user_attributes {
          attribute
          score
        }
        content {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          stage2 {
            stage_data
            stage_name
          }
          stage3 {
            stage_data {
              data
              needstate
            }
            stage_name
          }
        }
        food {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          stage2 {
            stage_data {
              data
              needstate
            }
            stage_name
          }

          stage3 {
            stage_data {
              data {
                avoid
                eat {
                  foods
                  ingredient
                  lookup
                  nsoverlap
                }
              }
              needstate
            }
            stage_name
          }
        }
        primary_persona
        product {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          # stage2 {
          #   stage_data
          #   stage_name
          # }
          # stage3 {
          #   stage_data
          #   stage_name
          # }
          stage4 {
            stage_data
            stage_name
          }
          stage5 {
            stage_data {
              prod_id
              prod_name
              score
              score_calculations
            }
            stage_name
          }
          stage6 {
            stage_data
            stage_name
          }
          stage7 {
            stage_name
            stage_data {
              data
              needstate
            }
          }
        }
        rules_fired {
          ID
          action {
            Needstate {
              ns
              score
            }
            BoostSku {
              skuId
              score
            }
            UserAttr {
              ns
              score
            }
            UserReason {
              attr
              priority
              reason
            }
            BoostHabitSku {
              hbSkuId
              score
            }
            Persona {
              person
              score
            }
          }
        }
      }
      recommendations {
        name
        persona
        recommendations {
          content
          products
          reason
          food {
            avoid
            eat {
              foods
              lookup
              # nsoverlap
              # spanish_ingredient
              ingredient
            }
          }
        }
      }
    }
  }
`;

export const GETMETARECOMMENDATIONBYID = gql`
  query MyQuery($test_id: String!) {
    getMetaRecommendationById(test_id: $test_id) {
      user
      latest
      inputs
      creator
      tags
      modified_date
      output {
        name
        persona
        recommendations {
          content
          food {
            avoid
            eat {
              foods
              lookup
              # nsoverlap
              # spanish_ingredient
              ingredient
            }
          }
          products
          reason
        }
      }
      metadata {
        aggregated_user_attributes {
          attribute
          score
        }
        content {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          stage2 {
            stage_data
            stage_name
          }
          stage3 {
            stage_data {
              data
              needstate
            }
            stage_name
          }
        }
        food {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          stage2 {
            stage_data {
              data
              needstate
            }
            stage_name
          }
          stage3 {
            stage_data {
              data {
                avoid
                eat {
                  foods
                  ingredient
                  lookup
                  nsoverlap
                }
              }
              needstate
            }
            stage_name
          }
        }
        primary_persona
        product {
          # stage1 {
          #   stage_data
          #   stage_name
          # }
          # stage2 {
          #   stage_data
          #   stage_name
          # }
          # stage3 {
          #   stage_data
          #   stage_name
          # }
          stage4 {
            stage_data
            stage_name
          }
          stage5 {
            stage_data {
              prod_id
              prod_name
              score
              score_calculations
            }
            stage_name
          }
          stage6 {
            stage_data
            stage_name
          }
          stage7 {
            stage_name
            stage_data {
              data
              needstate
            }
          }
        }
        rules_fired {
          ID
          action {
            BoostHabitSku {
              hbSkuId
              score
            }
            BoostSku {
              score
              skuId
            }
            Needstate {
              ns
              score
            }
            Persona {
              person
              score
            }
            UserAttr {
              ns
              score
            }
            UserReason {
              attr
              priority
              reason
            }
          }
        }
      }
    }
  }
`;
export const GETTESTSCENARIOSLIST = gql`
  query MyQuery {
    listTestScenario {
      test_id
      user
      version
      creator
      modified_date
      version
      tags
    }
  }
`;

export const CREATETESTSCENARIO = gql`
  mutation Mymut($metarecoinput: MetaRecoInput) {
    createTestScenario(metarecoinput: $metarecoinput) {
      status
      message
    }
  }
`;
export const UPDATETESTSCENARIO = gql`
  mutation Mymut($metarecoinput: MetaRecoInput) {
    updateTestScenario(metarecoinput: $metarecoinput) {
      status
      message
    }
  }
`;

export const DELETETESTSCENARIO = gql`
  mutation Mymut($delete_input: DeleteMetaInput) {
    deleteTestScenario(delete_input: $delete_input) {
      status
      message
    }
  }
`;

export const FILTERTESTSCENARIO = gql`
  query MyQuery($tags: [String!]!) {
    filterTestScenario(tags: $tags) {
      test_id
      user
      creator
      modified_date
      version
      tags
    }
  }
`;

export const CHECKTESTDIFFSTATE = gql`
  query MyQuery($test_input: DiffInput) {
    checkTestDiffState(test_input: $test_input) {
      status
      test_id
    }
  }
`;
