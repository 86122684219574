import _ from "lodash";
import { getContentFromContentStack } from "../core/contentStack";

export const getNeedStatesIds = async (data: any) => {
  let result: any;
  let arr: any = [];
  let combinedQuestions: any = [];
  for (let index = 0; index < data.length; index++) {
    result = await getContent(data[index]);

    arr.push(result[0]);
    if(localStorage.getItem("surveyType") === "shortSurvey") {
      let key = data[index].replace("ms_", "short_").concat("_combo");
      combinedQuestions.push(key);
  
      let key1 = data[index].replace("ms_", "short_").concat("_combo1");
      if (result[0][key1]) {
        combinedQuestions.push(key1);
      }
    }
    else {
    let key = data[index].replace("ms_", "").concat("_combo");
    combinedQuestions.push(key);

    let key1 = data[index].replace("ms_", "").concat("_combo1");
    if (result[0][key1]) {
      combinedQuestions.push(key1);
    }
  }
  }
  //let combinedQuestions: any = [];
  // for (let index = 0; index < data.length; index++) {
  //   const element = data[index].replace("ms_", "").concat("_combo");
  //   // const element = data[index];

  //   console.log("data element is", data[index]);
  //   // console.log("element is", arr[index]);
  //   // const element=
  //   combinedQuestions.push(element);
  // }

  // combinedQuestions.forEach((element: any) => {
  //   console.log("element is", element);
  // });
  let finalData: any = [];
  arr.forEach((responseCombo: any, index: number) => {
    //let combo = combinedQuestions[index];

    if (responseCombo) {
      for (let [key, value] of Object.entries(responseCombo)) {
        combinedQuestions.map((element: any) => {
          if (element === key) {
            finalData.push(value);
            // return;
          }
        });
        // if (key === combo) {
        //   finalData.push(value);
        // }
      }
    }
  });

  let finalArray: any = [];

  finalData.forEach((element: any) => {
    element.forEach((item: any) => {
      finalArray.push(item._content_type_uid);
    });
  });

  const uniqueIds = _.uniq(finalArray);
  return uniqueIds;
};

const getContent = (contentTypeId: string) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        console.log("error is", response.error_message);
      } else {
        return response && response[0];
      }
    })
    .catch((error) => {
      console.log(error);
    });
};