import { UPDATETESTSCENARIO } from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const updateTestScenario = async (testCaseInfoObj: any, props: any) => {
  return client
    .mutate({
      mutation: UPDATETESTSCENARIO,
      variables: {
        metarecoinput: testCaseInfoObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      res.status = 200;
      const resp = res;
      return resp;
    })
    .catch((err: any) => {
      if (err?.networkError?.response?.status === 401) {
        validateAdminOktaSession(props, true);
      } else {
        let isError = err;
        isError.error = true;
        return isError;
      }
    });
};
