import React from "react";
import { Animate } from "react-move";

interface AnimatedProgressProviderProps {
  children?: any;
  valueStart: number;
  valueEnd: number;
  duration: number;
  easingFunction: any;
}

interface AnimatedProgressProviderState {
  isAnimated: boolean;
  interval: any;
}

class AnimatedProgressProvider extends React.Component<
  AnimatedProgressProviderProps,
  AnimatedProgressProviderState
> {
  interval = undefined;

  constructor(props: AnimatedProgressProviderProps) {
    super(props);
    this.state = {
      isAnimated: false,
      interval: null,
    };
  }

  static defaultProps = {
    valueStart: 0,
  };

  componentDidMount() {
    this.setState({
      isAnimated: !this.state.isAnimated,
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart,
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart,
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction,
          },
        })}
      >
        {({ value }: any) => this.props.children(value)}
      </Animate>
    );
  }
}

export default AnimatedProgressProvider;
