import _ from "lodash";
import {
  createAnonymousSession,
  fetchAccessToken,
  fetchIdTokenForLogInUser,
  setAccessToken,
} from "../services/commerceServices/accessToken";

//this function manages session token
export async function validateCommerceSession() {
  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );

  const { idToken, accessToken } = tokens;
  if(_.isEmpty(accessToken)){
    await setAccessToken()
  }

  const accessTokenExpireAt = parseInt(accessToken && accessToken.expiryTime);
  const idTokenExpiresAt = parseInt(idToken && idToken.expiryTime);

  let currentTime = Math.round(new Date().getTime() / 1000);

  //check for accessToken expiry
  if (accessTokenExpireAt && accessTokenExpireAt < currentTime) {
    await fetchAccessToken().then((res: any) => {
      accessToken.access_token = res && res.access_token;
      accessToken.expires_in = res && res.expires_in;
      accessToken.expiryTime = currentTime + parseInt(res && res.expires_in);
    });
    //updates commerce-token-storage
    localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));
  }

  //check for idToken expiry for logged in and anonymous user
  if (idTokenExpiresAt && idTokenExpiresAt < currentTime) {

    if (localStorage.getItem("okta-token-storage")) {
      await fetchIdTokenForLogInUser().then((res) => {
        idToken.id_token = res && res.id_token;
        idToken.expiresAt =  res && res.expires_in;
        idToken.expiryTime = currentTime + parseInt(res && res.expires_in);
        // localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));

      });
    } else {
      await createAnonymousSession().then((res: any) => {
        idToken.id_token =
          res && res.securityToken && res.securityToken.idToken;
        idToken.expiryTime =
          currentTime +
          parseInt(res && res.securityToken && res.securityToken.expiresIn);
          // localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));

      });
    }

    //updates commerce-token-storage
    localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));
  }
}
