import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SUGGESRTIC_URL}`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = `${process.env.REACT_APP_SUGGESTIC_API_KEY}`;
  // const token="81697065de98231fbdf608e4af6ea9de4996f94f"
  const userId = localStorage.getItem("sg-user") || "";

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "Authorization":`Token ${token}`,
      "sg-user": userId,
    },
  };
});

const suggesticClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default suggesticClient