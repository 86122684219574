import axios, { AxiosError } from "axios";
import { validateCommerceSession } from "../../utils/validateCommerceSession";
import { getLanguage } from "./accessToken";

/**
 * API call to get/create cart-id
 */
export async function getOrCreateCart() {
  if (localStorage.getItem("okta-token-storage")) {
    await validateCommerceSession();
    const tokens = JSON.parse(
      localStorage.getItem("commerce_token_storage") || "[]"
    );
    const { idToken, accessToken } = tokens;
    // let totalCartCount = parseInt(
    //   localStorage.getItem("totalCartProductsCount") || ""
    // );
    // if (totalCartCount > 0) {
    //   let cart_id: any = {};
    //   if (localStorage.getItem("okta-token-storage")) {
    //     cart_id = localStorage.getItem("cartCode");
    //   } else {
    //     cart_id = localStorage.getItem("cartId");
    //     // localStorage.setItem("cartId", response?.data?.guid);
    //   }
    //   tokens.cart_id = cart_id;
    //   localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));
    //   return;
    // }
    // const tokens = JSON.parse(
    //   localStorage.getItem("commerce_token_storage") || "[]"
    // );
    // const { idToken, accessToken } = tokens;

    let data = JSON.stringify({});
    let config: any = {
      method: "get",
      url: `${process.env.REACT_APP_CART_URL}/${
        process.env.REACT_APP_BASE_SITE_ID
      }/users/${idToken && idToken.partyId}/account/${
        idToken && idToken.accountId
      }/carts/default`,

      headers: {
        id_token: `${idToken && idToken.id_token}`,
        Authorization: `Bearer ${accessToken && accessToken.access_token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config)
      .then(async (response: any) => {
        let cart_id: any = {};
        if (localStorage.getItem("okta-token-storage")) {
          cart_id = response && response.data && response.data.code;
          localStorage.setItem("cartCode", response?.data?.code);
        } else {
          // cart_id = response && response.data && response.data.guid;
          // localStorage.setItem("cartId", response?.data?.guid);
        }
        tokens.cart_id = cart_id;
        localStorage.setItem("commerce_token_storage", JSON.stringify(tokens));
        getCartEntries()
        return response && response.data;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
}

/**
 * API call to merge annonymous cart entries
 */
export async function cartMergingCall(uuid?: any) {
  await validateCommerceSession();
  const cartId = localStorage.getItem("cartId");
  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken, cart_id } = tokens;
  let config: any = {
    method: "post",
    url: `${process.env.REACT_APP_CART_URL}/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${idToken && idToken.partyId}/account/${
      idToken && idToken.accountId
    }/carts/${cart_id}?anonymousCartUuid=${cartId}&includeEntries=true`,
    headers: {
      Accept: "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
    },
  };

  axios(config)
    .then(async function (response: any) {
      localStorage.removeItem("cartId");
      tokens.cart_id=response?.data?.code
      localStorage.setItem("commerce_token_storage",JSON.stringify(tokens))
      await getCartEntries();
      // console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

/**
 * API call to fetch cart entries
 */
export async function getCartEntries() {
  await validateCommerceSession();
  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken, cart_id } = tokens;
  var config: any = {
    method: "get",
    url: `${process.env.REACT_APP_CART_URL}/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${idToken && idToken.partyId}/account/${
      idToken && idToken.accountId
    }/carts/${cart_id}/entries?lang=${getLanguage()}&pageSize=100`,
    headers: {
      Accept: "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
    },
  };

  return axios(config)
    .then(async (response: any) => {
      let totalCartQuantity = response.data.entries.reduce(
        (q1: any, q2: any) => (q1 = q1 + q2.quantity),
        0
      );
      await localStorage.setItem("totalCartProductsCount", totalCartQuantity);
      return response.data;
    })
    .catch(async (error: AxiosError) => {
      console.log(error);
    });
}

/**
 * API call to add product to cart
 */
export async function addCartEntries(productCode: number, quantity: number) {
  await validateCommerceSession();

  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken, cart_id } = tokens;

  let data = JSON.stringify({
    product: {
      code: productCode,
    },
    quantity: quantity,
  });

  let config: any = {
    method: "post",
    url: `${process.env.REACT_APP_CART_URL}/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${idToken && idToken.partyId}/account/${
      idToken && idToken.accountId
    }/carts/${cart_id}/entries?apiClient=WEB&&lang=${getLanguage()}`,
    headers: {
      Accept: "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
}

/**
 * API call to delete cart entries .
 */
export async function deleteCartEntries(entryNumber: number) {
  await validateCommerceSession();

  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken, cart_id } = tokens;

  // var data = JSON.stringify({
  //   product: {
  //     code: "296753",
  //   },
  //   quantity: 1,
  // });

  let config: any = {
    method: "delete",
    url: `${process.env.REACT_APP_CART_URL}/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${idToken && idToken.partyId}/account/${
      idToken && idToken.accountId
    }/carts/${cart_id}/entries/${entryNumber}?lang=${getLanguage()}`,
    headers: {
      Accept: "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
      "Content-Type": "application/json",
    },
    // data: data,
  };

  return axios(config)
    .then((response: any) => {
      // console.log(response.data);
      return response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
}

/**
 * API call to deduct cart entries count .
 */
export async function deductCartEntriesCount(
  productCode: number,
  quantity: number,
  entryNumber: number
) {
  await validateCommerceSession();

  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken, cart_id } = tokens;
  let data = JSON.stringify({
    product: {
      code: productCode,
    },
    quantity: quantity,
  });

  let config: any = {
    method: "put",
    url: `${process.env.REACT_APP_CART_URL}/${
      process.env.REACT_APP_BASE_SITE_ID
    }/users/${idToken && idToken.partyId}/account/${
      idToken && idToken.accountId
    }/carts/${cart_id}/entries/${entryNumber}?lang=${getLanguage()}`,
    headers: {
      Accept: "application/json",
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then((response: any) => {
      return response && response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
}
