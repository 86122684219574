import { getContentFromContentStack } from "../core/contentStack";

export const getGroupedReasonsData = async (data: any) => {
  let arr: any = [];
  for (let index = 0; index < data?.length; index++) {
    let Obj: any = {};
    Obj.name = data[index]?.name;
    Obj.content = data[index]?.recommendations?.reason;
    if (Obj?.content && Obj?.content?.length > 0) {
      arr.push(Obj);
    }
  }
  let newArr = [...arr];
  for (let index = 0; index < newArr?.length; index++) {
    const allReasons = getReasonsData(newArr[index]?.content);
    const dataReason = await getAllReasons(allReasons);
    newArr[index] = Object.assign({}, newArr[index], {
      reasonsData: dataReason,
    });
  }

  return newArr;
};
const getAllReasons = (reasonData: any) => {
  return reasonData.then(function (data: any) {
    let returnedData = data;
    return returnedData;
  });
};

const getReasonsData = async (data: any) => {
  let res: any = await getContent("ms_product_phrases");
  let result: any;
  let arr: any = [];
  arr.push(res?.[0]?.starting_text);
  for (let index = 0; index < data?.length; index++) {
    let value: any = data?.[index]?.replaceAll("-", "_");
    result = res[0][value]?.toLowerCase();
    result && arr.push(result);
  }
  arr.push(res?.[0]?.ending_text);
  return arr;
};

const getContent = (contentTypeId: string) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        console.log("error is", response.error_message);
      } else {
        return response && response[0];
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
