import {
  GETMETARECOMMENDATION,
  GETMETARECOMMENDATIONBYID,
} from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const handleMetaRecoResultAPI = (answersObj: any,props:any) => {
  return client
    .query({
      query: GETMETARECOMMENDATION,
      variables: {
        answers: answersObj,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      //createTestScenario(JSON.stringify(res.metadata));
      localStorage.setItem("meta_result", JSON.stringify(res));
      return res;
    })
    .catch((err: any) => {
      if(err?.networkError?.response?.status===401){
        validateAdminOktaSession(props,true)
      }else {
        let isError = err;
        isError.error = true;
        return isError;
      }
    });
};

export const handleMetaRecoResultAPIById = (test_id: string,props:any) => {
  return client
    .query({
      query: GETMETARECOMMENDATIONBYID,
      variables: {
        test_id: test_id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      localStorage.setItem("meta_result", JSON.stringify(res));
      return res;
    })
    .catch((err: any) => {
      if(err?.networkError?.response?.status===401){
        validateAdminOktaSession(props,true)
      }else {
        let isError = err;
        isError.error = true;
        return isError;
      }
    });
};
