import axios from "axios";
import _ from "lodash";
import { validateCommerceSession } from "../../utils/validateCommerceSession";
import { setAccessToken } from "./accessToken";


/**
 * API call to fetch LynxValueAttribution
 */
export async function fetchLynxValueAttribution() {
  await validateCommerceSession();
  await setAccessToken()
  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || ""
  );
  const aboMetaData = JSON.parse(localStorage.getItem("aboMetaData") || "[]");
  const { linkId } = aboMetaData?.data || "";
  const { idToken, accessToken } = tokens;
  let config: any = {
    method: "get",
    url: `${process.env.REACT_APP_COMMERCE_BASE_URL}/AttributionService/1.0.0/generate-token/share-bar/${linkId}`,
    headers: {
      id_token: `${idToken && idToken.id_token}`,
      Authorization: `Bearer ${accessToken && accessToken.access_token}`,
    },
  };

  return axios(config)
    .then(function (response) {
    //   console.log(response?.data?.token);
      return response?.data?.token;
    })
    .catch(function (error) {
      return error;
    });
}
