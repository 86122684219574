import { ContentType } from "contentstack";
import _, { drop } from "lodash";
import { getContentFromContentStack } from "../core/contentStack";
import { foundationalArray } from "./getQuestionsIds";

const getContentForMain = (contentTypeId: string, index?: any) => {
  return getContentFromContentStack(contentTypeId)
    .then(async (res) => {
      if (res && res.error_message) {
      } else {
        if (res?.[0]?.[0]?.question_type !== undefined) {
          let mainObj: any = {};
          mainObj.id = contentTypeId;
          let Obj: any = {};
          Obj.id= contentTypeId;
          Obj.question_title = res?.[0]?.[0]?.question_title;
          Obj.question_value = res?.[0]?.[0]?.reco_id;
          Obj.question_type = res?.[0]?.[0]?.question_type;
          Obj.options_list = _.map(
            getUpdatedOptionsList(res?.[0]?.[0]?.options_list),
            (key, vaue) => {
              return _.pick(key, ["id", "value"]);
            }
          );
          Obj.dependent = [];
          let Arr2: any = [];

          if (res?.[0]?.[0]?.question_type === "dropdown") {
            let count = 0;
            Obj.dropdown = _.map(
              res?.[0]?.[0]?.food_combo,
              "_content_type_uid"
            );
          }
          if (res?.[0]?.[0]?.restriction_options) {
            await fetch(res?.[0]?.[0]?.restriction_options?.url)
              .then(async (response) => response && response.json())
              .then(async (output) => {
                const data: any = output;
                const data2: any = _.map(data, (key, value) => {
                  let obj: any = _.pick(key, ["id", "name"]);
                  obj.value = obj.name;
                  delete obj.name;
                  return obj;
                });
                Obj.options_list = data2;
              })
              .catch((err) => console.log("error is", err));
          }
          let dummyArr: any = [];
          //needstate questions are handled here
          if (
            res?.[0]?.[0]?.question_type === "ranking" ||
            res?.[0]?.[0]?.question_type === "short_survey_ranking"
          ) {
            let allNeedState = await _.map(
              await getUpdatedOptionsList(res?.[0]?.[0]?.options_list),
              "needStateId"
            );
            let isShortSurvey =
              res?.[0]?.[0]?.question_type === "short_survey_ranking"
                ? true
                : false;
            let tempObj: any = {};
            Obj.question_value = "NeedstateRank";
            for (let key1 = 0; key1 < allNeedState.length; key1++) {
              let dummyObj: any = {};

              if (allNeedState[key1] !== "not_available") {
                // eslint-disable-next-line no-loop-func

                await getNeedStatesIds(
                  [allNeedState[key1]],
                  isShortSurvey
                ).then(async (data) => {
                  let options: any = getUpdatedOptionsList(
                    res?.[0]?.[0]?.options_list
                  );
                  dummyObj.questions = data;
                  dummyObj.conditions = [options?.[key1].id];
                  dummyArr.push(dummyObj);
                });
              }
            }
            Obj.dependent = dummyArr;
          }

          let dummyArr2: any = [];
          if (res?.[0]?.[0]?.dependent !== undefined) {
            let dummyObj: any = {};
            dummyObj.conditions = await _.difference(
              _.map(getUpdatedOptionsList(res?.[0]?.[0]?.options_list), "id"),
              res?.[0]?.[0]?.dependent?.conditions
            );
            dummyObj.questions =
              res?.[0]?.[0]?.dependent?.dependent_content_type_id;
            dummyArr2.push(dummyObj);
            Obj.dependent = dummyArr2;
          }
          mainObj.content = Obj;
          return mainObj;
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentForFoundational = (contentTypeId: string, index?: any) => {
  return getContentFromContentStack(contentTypeId)
    .then(async (res) => {
      return res?.[0]?.[0];
    })
    .catch((error) => {
      console.log(error);
    });
};

export async function getMasterJsonData(callBackFunction?: any) {
  var foundationalQues = await getContentForFoundational(
    "ms_foundational_ques"
  ).then((res: any) => {
    return res;
  });
  var shortSurvey = foundationalArray(
    foundationalQues?.short_foundational_ques
  );
  var fullSurvey = foundationalArray(foundationalQues?.foundational_questions);

  let contentTypeIds = _.union(fullSurvey, shortSurvey);

  return getArray(contentTypeIds, 0, async (res: any, mainCount: any) => {
    const fullPath = fullSurvey.map((i: any) => {
      let obj = res.find((j: any) => j.id === i);
      return obj?.content;
    });
    const shortPath = shortSurvey.map((i: any) => {
      let obj = res.find((j: any) => j.id === i);
      return obj?.content;
    });

    let dependentData = _.map(_.union(fullPath, shortPath), (obj: any) => {
      if (obj?.question_type === "dropdown") {
        return obj.dropdown.flat();
      }
      if (obj?.dependent.length > 0) {
        return _.map(obj?.dependent, "questions").flat();
      }
    });
    let updatedContentIds = _.compact(dependentData)
      .flat()
      .filter((a) => !contentTypeIds.includes(a));

    getArray(_.uniq(updatedContentIds), mainCount, async (response: any) => {
      let allContentTypesData = _.union(res, response);
      const fullPath = fullSurvey.map((i: any) => {
        let obj: any = allContentTypesData.find((j: any) => j.id === i);
        return obj?.content;
      });
      const shortPath = shortSurvey.map((i: any) => {
        let obj: any = allContentTypesData.find((j: any) => j.id === i);
        return obj?.content;
      });
      var finalContents: any = {};
      finalContents.fullSurvey = getFinalContentForPath(
        _.compact(fullPath),

        allContentTypesData
      );
      finalContents.shortSurvey = getFinalContentForPath(
        _.compact(shortPath),

        allContentTypesData
      );
      if (
        finalContents.fullSurvey !== undefined &&
        finalContents.shortSurvey !== undefined
      ) {
        return callBackFunction(finalContents);
      }
    });
  });
}

const getFinalContentForPath = (pathQuestion: any, mainResponse: any) => {
  let dependentArr: any = [];
  let data = pathQuestion.map((obj: any) => {
    let element = obj;
    var count = 0;
    if (obj.question_type === "dropdown") {
      let dropdownCombo = obj.dropdown.map((item: any) => {
        let tempObj = _.find(mainResponse, (obj: any) => obj.id === item);
        return tempObj?.content;
      });
      delete element["dropdown"]
      element.dropdown_combo = dropdownCombo;
      return element;
    } else if (obj.question_value?.toLowerCase() === "needstaterank") {
      var Arr: any = [];
      let counter = 0;
      const dependent = obj?.dependent.map((ele: any) => {
        let depElement = _.cloneDeep(ele);
        if (ele?.questions.length === 0) {
          counter++;
        }
        let temp: any = [];
        let dependentCombo =
          depElement?.questions?.length > 0
            ? depElement?.questions.map((item: any) => {
                let tempObj = _.find(
                  mainResponse,
                  (obj: any) => obj.id === item
                );
                return tempObj?.content;
              })
            : [];

        depElement.questions = getUniqueQuestions(
          _.compact(dependentCombo),
          temp.flat()
        );
        return depElement;
      });
      obj.dependent = getNeedstateData(_.cloneDeep(dependent), mainResponse);

      return obj;
    } else if (
      obj.dependent.length > 0 &&
      obj.question_value?.toLowerCase() !== "needstaterank"
    ) {
      let dependentCombo = obj?.dependent.map((ele: any) => {
        let temp =
          ele.questions?.length > 0
            ? ele?.questions.map((item: any) => {
                let tempObj = _.find(
                  mainResponse,
                  (obj: any) => obj.id === item
                );
                return tempObj?.content;
              })
            : [];
        dependentArr.push(temp.flat());
        ele.questions = temp;
        return ele;
      });
      element.dependent = dependentCombo;
      return element;
    } else {
      return element;
    }
  });
  let temp = getUniqueQuestions(data, dependentArr.flat());
  let finalArr=temp.filter((item:any) => item.id !== "ms_name")
  return finalArr;
};
const getUniqueQuestions = (Array1: any, Array2: any) => {
  let updatedArray = Array1.filter(function (objFromA: any) {
    return !Array2.find(function (objFromB: any) {
      return objFromA.id === objFromB.id;
    });
  });
  return updatedArray;
};
const getNeedstateData = (Array: any, referArray: any) => {
  let finalArr: any = [];
  for (let i = 0; i < Array.length; i++) {
    let question = _.cloneDeep(Array[i].questions);
    let dependentArray: any = [];
    question.map((element: any) => {
      const dependentQues = element?.dependent?.[0]?.questions;
      if (dependentQues?.length > 0) {
        let data = dependentQues.map((item: any) => {
          const needStateData = _.find(
            question,
            (obj: any) => obj.id === item || obj.id === item.id
          );
          dependentArray.push(needStateData);
          return needStateData;
        });
        element.dependent[0].questions = data;
      }
    });
    let finalQuestions = question.filter(
      (x: any) =>
        !_.cloneDeep(dependentArray).filter((y: any) => y?.id === x?.id).length
    );
    Array[i].questions = finalQuestions;
    finalArr.push(Array[i]);
  }
  return finalArr;
};

export async function getArray(
  contentTypes: any,
  counter: number,
  callBackFunction?: any,
  mainCall?: boolean
) {
  var count = 0;
  let Arr: any = [];
  return _.forEach(contentTypes, async (item, index) => {
    let tempData = await getContentForMain(item, index).then((res: any) => {
      return res;
    });
    count++;
    Arr.push(tempData);

    if (count === contentTypes.length) {
      return callBackFunction(_.compact(Arr), counter);
    }
  });
}

//this function updates the format of options we recieve from CMS
export const getUpdatedOptionsList = (optionsList: Object) => {
  const optionsArray: Array<Object> = [];
  _.forEach(_.entries(Object(optionsList)), ([key, value]: any) => {
    // let Obj: any = {};
    const Obj: any = {
      value: value[0],
      id: value[1],
      lifeomic: value[2],
      isVisible: value[3],
      ...(value[4] && { needStateId: value[4] }),
      ...(value[5] && { imageSrc: value[5] }),
      ...(value[6] && { isMandatory: value[6] }),
    };
    optionsArray.push(Obj);
  });
  return optionsArray;
};

const getNeedStatesIds = async (data: any, isShortSurvey: boolean) => {
  let result: any;
  let arr: any = [];
  let combinedQuestions: any = [];
  for (let index = 0; index < data.length; index++) {
    result = await getContent(data[index]);

    arr.push(result[0]);
    if (isShortSurvey) {
      let key = data[index].replace("ms_", "short_").concat("_combo");
      combinedQuestions.push(key);

      let key1 = data[index].replace("ms_", "short_").concat("_combo1");
      if (result[0][key1]) {
        combinedQuestions.push(key1);
      }
    } else {
      let key = data[index].replace("ms_", "").concat("_combo");
      combinedQuestions.push(key);

      let key1 = data[index].replace("ms_", "").concat("_combo1");
      if (result[0][key1]) {
        combinedQuestions.push(key1);
      }
    }
  }

  let finalData: any = [];
  arr.forEach((responseCombo: any, index: number) => {
    //let combo = combinedQuestions[index];

    if (responseCombo) {
      for (let [key, value] of Object.entries(responseCombo)) {
        combinedQuestions.map((element: any) => {
          if (element === key) {
            finalData.push(value);
            // return;
          }
        });
        // if (key === combo) {
        //   finalData.push(value);
        // }
      }
    }
  });

  let finalArray: any = [];

  finalData.forEach((element: any) => {
    element.forEach((item: any) => {
      finalArray.push(item._content_type_uid);
    });
  });

  const uniqueIds = _.uniq(finalArray);
  return uniqueIds;
};

const getContent = (contentTypeId: string) => {
  return getContentFromContentStack(contentTypeId)
    .then((response) => {
      if (response && response.error_message) {
        console.log("error is", response.error_message);
      } else {
        return response && response[0];
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
