// If you aren't worried about calling utag before it has loaded (e.g. user interactions)
// This will try to call Tealium but will just drop the event if utag is not loaded

export const utag = window.utag || { link: () => {}, view: () => {} };

// Usage: just use the exported utag, don't use the utag on window
// utag.link({ ... })

// If the utag call could possibly happen before utag has loaded (e.g. page load)
// This will make the call as soon as utag has loaded (it still drops it if utag doesn't load in a certain amount of time)

export const withUtag = async () => {
  if (window.utag) return window.utag;
  let i = 0;
  while (i < 50) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    if (window.utag) return window.utag;
    i = i + 1;
  }
  return { link: () => {}, view: () => {} };
};

// Usage: Use the resolved utag object from the Promise
// withUtag().then(utag => utag.view({ ... }))
