export const handleNestedSort = (unordered: any, sortArrays = false) => {
  if (!unordered || typeof unordered !== "object") {
    return unordered;
  }

  if (Array.isArray(unordered)) {
    const newArr: any = unordered.map((item) =>
      handleNestedSort(item, sortArrays)
    );
    if (sortArrays) {
      newArr.sort();
    }
    return newArr;
  }

  const ordered: any = {};
  Object.keys(unordered)
    .sort()
    .forEach((key) => {
      ordered[key] = handleNestedSort(unordered[key], sortArrays);
    });
  return ordered;
};
