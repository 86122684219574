export const formattedText = (text: string) => {
  let formattedText = text
    .replaceAll("-", " ")
    .replaceAll(".", " ")
    .replace(/@[^@]+$/, "")
    .toLowerCase()
    .split(" ")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedText;
};
