import _ from "lodash";

//this function manages session token
export async function validateOktaSession(props?: any) {
  let oktaToken: any = localStorage.getObj("okta-token-storage");
  if (oktaToken && _.isEmpty(oktaToken)) {
    sessionStorage.setItem("login-session-expired", "true");
  }
}

//this function manages session token
export async function validateAdminOktaSession(props: any, isError?: boolean) {
  let oktaToken: any = sessionStorage.getObj("okta-token-storage");
  if ((oktaToken && _.isEmpty(oktaToken)) || isError === true) {
    sessionStorage.setItem("isAdminSessionExpired", "true");
    // props.history.push("/admin")
    props.oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/admin`,
    });
  }
}
