import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { fetchLynxValueAttribution } from "./commerceServices/attributionServices";
/**
 * API calls to get shareable link and information
 */
async function getShareableLink() {
  let encodedData = localStorage.getItem("okta-token-storage");
  let encodedData1 = encodedData ? JSON.parse(encodedData) : null;
  var results: any = {};
  const token =
    (encodedData1 &&
      encodedData1.accessToken &&
      encodedData1.accessToken.accessToken) ||
    "";
  let decoded: any = token ? jwt_decode(token) : "";

  const partyId = decoded ? decoded.partyId : ""; //"3744170";
  const salesPlanAff =
    decoded && decoded.account ? decoded.account.sales_plan_aff : ""; //"010";
  const abo = decoded && decoded.account ? decoded.account.abo : "";
  let baseLink: any = "";
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    baseLink = `${process.env.REACT_APP_LINK_SHARE_BASE_LINK}`;
  } else {
    baseLink = window.location.origin
  }

  await (async function () {
    if (partyId && salesPlanAff) {
      const generateLink = `${process.env.REACT_APP_GENERATE_SHAREABLE_URL}`;

      let data = {
        "destinationSystem": {
          "channel": "wellness_reco",
          "subChannel": "web",
          "link": baseLink + "/", // "window.location.origin + "/",
          "country": "US"
        },
        "sourceSystem": {
          "channel": "WEB",
          "subChannel": "wellness_reco",
        },
        "generator": {
          "aff": salesPlanAff,
          "partyId": partyId,
          "abo": abo,
          "volumeAttribution": true
        },
        "attributionLink": baseLink + "/?id=",//window.location.origin + "/?id="
      };

      // API call for generaing shareable link
      return axios
        .post(generateLink, data, {
          headers: {
            //'Authorization' : `Bearer ${token}` //uncomment this code when mdms service is integrated with okta app
            'Authorization': `${"Bearer " + process.env.REACT_APP_AUTH_TOKEN}`,
            'x-api-key': `${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          console.log("Getting shareable link information", res);
          results = res;
        });
    }
  })();
  return results;
}

function getLinkMetadata(id: any) {
  console.log("[abotoolserv] >> [getLinkMetadata]");
  let geteMetadataURL = `${process.env.REACT_APP_META_DATA_URL + id}`;
  axios.get(geteMetadataURL, {
    headers: {
      //'Authorization' : `Bearer ${token}` //uncomment this code when mdms service is integrated with okta app
      'Authorization': `${"Bearer " + process.env.REACT_APP_AUTH_TOKEN}`,
      'x-api-key': `${process.env.REACT_APP_AUTH_TOKEN}`,
    },
  })
    .then((res) => {
      console.log("Getting link metadata infor", res);
      const aboMetaData = res ? JSON.stringify(res) : "";
      localStorage.setItem('aboMetaData', aboMetaData);
      //let aboData = localStorage.getItem("aboMetaData");
      // const aboMetaData = JSON.parse(localStorage.getItem("aboMetaData") || "[]");
      if (res?.data?.linkId) {
        let token = "";
        fetchLynxValueAttribution().then((res: any) => {
          token = res;
          Cookies.set("LynxValueAttribution", token, { domain: `${process.env.REACT_APP_WELLNESS_DOMAIN}` });
        });
      }
    });
}

export { getLinkMetadata, getShareableLink };
