import { gql } from "@apollo/client";

// This query is used to create new user
export const CREATE_USER = gql`
  mutation createUser($name: String!, $email: String!) {
    createUser(name: $name, email: $email) {
      success
      message
      user {
        id
        databaseId
        profileId
      }
    }
  }
`;

// This query is used to get user for consent agreement
export const FETCH_USER_PROFILE = gql`
  query searchProfile($email: String!) {
    searchProfile(email: $email) {
      id
      programName
      userId
    }
  }
`;

// This query is used to set custom attributes
export const SET_CUSTOM_ATTRIBUTES = gql`
  mutation createProfileCustomAttributes(
    $attributes: [ProfileCustomAttribute!]!
  ) {
    createProfileCustomAttributes(attributes: $attributes) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

// This query is used to update language
export const UPDATE_USER_LANGUAGE = gql`
  mutation updateProfile($language: Language) {
    updateProfile(language: $language) {
      success
    }
  }
`;

// This query is used to get the programs
export const GET_PROGRAMS = gql`
  query programs {
    programs {
      edges {
        node {
          id
          name
          restrictions(encoded: true)
        }
      }
    }
  }
`;

// This query is used to set user program
export const SET_USER_PROGRAM = gql`
  mutation updateUserProgram($programId: String!) {
    updateUserProgram(programId: $programId) {
      success
    }
  }
`;

// This query is used to get the restrictions
export const GET_RESTRICTIONS = gql`
  query restrictions {
    restrictions {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

// This query is used to set user restrictions
export const SET_USER_RESTRICTION = gql`
  mutation profileRestrictionsUpdate($restrictions: [ID]) {
    profileRestrictionsUpdate(restrictions: $restrictions) {
      success
    }
  }
`;

//This query has to be used

export const FETCH_RECIPE_BY_INGRIDIENTS = gql`
  query recipeSearch($first: Int, $filter: FoodFilter) {
    recipeSearch(first: $first, filter: $filter) {
      edges {
        node {
          id
          name
          ingredientsCount
          ingredientLines
          numberOfServings
          totalTime
          mainImage
          instructions
          ingredients {
            name
          }
        }
      }
    }
  }
`;

// This query is used to get user for consent agreement
// export const FETCH_RECIPE_BY_INGRIDIENTS = gql`
//   query recipeSearch($query: String, $first: Int) {
//     recipeSearch(query: $query, first: $first) {
//       edges {
//         node {
//           id
//           name
//           ingredientsCount
//           ingredientLines
//           numberOfServings
//           totalTime
//           squareImage
//           instructions
//           ingredients {
//             name
//           }
//         }
//       }
//     }
//   }
// `;
