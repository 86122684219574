import { GETTESTSCENARIOSLIST } from "../../queries/admintooling";
import client from "../../utils/init-appSync";
import { validateAdminOktaSession } from "../../utils/validateOktaSession";

export const listTestScenarios = (props: any) => {
  return client
    .query({
      query: GETTESTSCENARIOSLIST,
      fetchPolicy: "no-cache",
    })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      if (err?.networkError?.response?.status === 401) {
        validateAdminOktaSession(props, true);
      } else {
        let isError = err;
        isError.error = true;
        return isError;
      }
    });
};
