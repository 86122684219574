import _ from "lodash";

export let getUtagAuthObject = () => {
  const data: any = localStorage.getItem("user-info");
  const parsedData =
    (data !== "undefined" || data !== "null") && JSON.parse(data);
  if (parsedData && !_.isEmpty(parsedData)) {
    return {
      visitor_imcID:
        (
          Number(parsedData?.salesPlanAff) * 100000000000 +
          Number(parsedData?.aboNumber)
        ).toString() || "",
      visitor_partyID: parsedData?.partyId || "",
      visitor_pinNumber: parsedData?.pinNumber || "",
    };
  }
};

export let getUtagSessionObject = () => {
  const currentLanguage =
    sessionStorage.getItem("languageTypeOfApp") || "en-us";
  const data: any = localStorage.getItem("user-info");
  const parsedData =
    (data !== "undefined" || data !== "null") && JSON.parse(data);
  return {
    site_country: "us",
    site_currencyCode: "usd",
    site_language: currentLanguage?.replace("-us", "") || "",
    site_prefix: "amway",
    site_region: "americas",
    site_subRegion: "north america",
    site_type: "responsive",
    site_type_short: "web",
    site_webProperty: "wellness recommender",
    site_webProperty_mod: "amway.com | wellness recommender",
    visitor_userProfile: parsedData?.accountSubType?.toLowerCase() || "guest",
    wellness_userID: parsedData?.accountId || "anonymous",
  };
};

export const getUtagNeedStates = () => {
  const resp = localStorage.getObj("reco-result");
  const dataResponse = _.cloneDeep(
    resp?.data?.getRecommendation || resp?.data?.getRecommendationById
  );
  let arr: any = [];
  for (let index = 0; index < dataResponse?.length; index++) {
    const element = dataResponse[index]?.name;

    arr.push(element);
  }
  return { wellness_userNeeds: arr };
};
