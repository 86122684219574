import axios, { AxiosError } from "axios";
import { response } from "../components/results-landing/mock-response";
import {
  createAnonymousSession,
  removeHybrisSession,
} from "../services/commerceServices/accessToken";
import { getCartEntries, getOrCreateCart } from "../services/commerceServices/cartServices";

//intercepts axios calls
export function axiosInterceptor() {
  const tokens = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "[]"
  );
  const { idToken, accessToken } = tokens;
  const id_Token = idToken && idToken.id_token;

  //intercepts request call
  axios.interceptors.request.use(
    (config: any) => {
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  //intercepts response call
  axios.interceptors.response.use(undefined, async (err: any) => {
    if (
      (err.request?.responseURL?.includes("ProductSearch") ||
        err.request?.responseURL?.includes("Cart") ||
        err.request?.responseURL?.includes("AttributionService")) &&
      !localStorage.getItem("okta-token-storage")
    ) {
      if (err.response.status === 401 && !err?.config?.__isRetryRequest) {
        let responseData: any;
        err.config.__isRetryRequest = true;
        // /session is recalled
        await createAnonymousSession().then(async (res: any) => {
          //Case 1: if on recalling session tokens didn't get refreshed
          if (id_Token === res?.securityToken?.idToken) {
            await removeHybrisSession();
            await createAnonymousSession().then(async (res: any) => {
              let config = err?.response?.config;
              config.header.id_token = res?.securityToken?.idToken;
              return await axios(config).then(async (response: any) => {
                responseData = response;
              });
            });
          } else {
            //Case 2: If tokens gets refreshed
            let config = err?.response?.config;
            config.headers.id_token = res?.securityToken?.idToken;
            return await axios(config).then(async (response: any) => {
              responseData = response;
            });
          }
        });
        if (responseData) {
          return Promise.resolve(responseData);
        } else {
          Promise.reject(responseData);
        }
      } else {
        return Promise.reject(err);
      }
    }
  });
}
