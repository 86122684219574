
// export const oktaAuthConfig = {
//   // Note: If your app is configured to use the Implicit flow
//   // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//   // you will need to add `pkce: false`
//   // issuer: 'https://account-amerreg-qa.amwayglobal.com/v1/proxy/oauth2/ausniwuqpv35zpr9e0h7',
//   issuer: 'https://amwayconnect-amerreg-qa.oktapreview.com/oauth2/ausniwuqpv35zpr9e0h7',
//   authorizeUrl: 'https://account-amerreg-qa.amwayglobal.com/v1/proxy/oauth2/ausniwuqpv35zpr9e0h7/v1/authorize',
//   redirectUri: window.location.origin + '/login/callback',
//   clientId: '0oasu8jpgx9lGtKS90h7',
//   // tokenUrl: 'https://idp-amerreg-qa.amwayglobal.com/oauth2/ausniwuqpv35zpr9e0h7/v1/token',
//   // issuer: 'https://amway.okta.com/oauth2/default',
//   // issuer: 'https://account-amerreg-qa.amwayglobal.com/v1/proxy/oauth2/ausniwuqpv35zpr9e0h7/v1/authorize' +
//   // '/?client_id=' + '0oasu8jpgx9lGtKS90h7' +
//   // '&clientapp=' + 'personal-wellness-us' +
//   // '&cancelRedirect=' + 'com.amway.nutrilitepersonalized://oauth',
//   // clientId: '0oasu8jpgx9lGtKS90h7',
//   //clientapp: 'personal-wellness-us',
//   // redirectUri: 'com.amway.nutrilitepersonalized://oauth',
//   // redirectUri: window.location.origin + '/login/callback',
//   //   clientId: '0oasu8jpgx9lGtKS90h7',
//   scope: 'openid offline_access',
//   // responseType: 'id_token',
//   pkce: true,
//   authParams: {
//     cancelRedirect: window.location.origin + '/login/callback',
//     scope: ['openid' , 'offline_access'],
//     responseType: ['id_token', 'token']
//   },
//   logoutUrl: 'https://account-amerreg-qa.amwayglobal.com/signout?clientapp=personal-wellness-us&redirect=' + encodeURIComponent('http://localhost:3000'),
//   // postLogoutRedirectUri: 'https://account-amerreg-qa.amwayglobal.com/signout?clientapp=personal-wellness-us&redirect=http://localhost:3000/login/callback&lng=es-es',
//   // postLogoutRedirectUri: window.location.origin + '/logout/callback',
//   // logoutUrl: 'http://localhost:3000',
//   tokenManager: {
//     storage: 'sessionStorage',
//     autoRenew: true,
//     expireEarlySeconds: 30
//   }
// };

import { createSuggesticUser } from "../../services/suggesticServices";

//This method is used for handle Amway ID Login Funcationality

export const Login = async (props: any) => {
  localStorage.setObj("isAuthCalled",false);
  await props.signInWithRedirect()
}

//This method is used for handle Amway ID authentication Okta OIDC config  
export const AmwayIDOktaConfig = () => {
  let languageType = sessionStorage.getItem("languageTypeOfApp") || "en-us";
  const oktaAuthConfig = {
    issuer: `${process.env.REACT_APP_AUTH_ISSUER}`,
    authorizeUrl: `${process.env.REACT_APP_AUTHORIZE_URL}` +
      '?client_id=' + `${process.env.REACT_APP_CLIENT_ID}` +
      '&amw_clientapp=' + `${process.env.REACT_APP_CLIENT_APP_NAME}` +
      '&cancelRedirect=' + encodeURIComponent(window.location.origin + '/loginsuccessful') +
      '&amw_lng=' + languageType + 
      '&scope=' + 'openid profile abo',
    //'&scope=' + 'offline_access'+
    //'&z=',
    redirectUri: window.location.origin + '/login/callback',
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    pkce: false,
    scopes: ["openid", "email", "profile", "offline_access", "abo"],
    logoutUrl: `${process.env.REACT_APP_LOGOUT_BASE_URL}` + '/signout?clientapp=' + `${process.env.REACT_APP_CLIENT_APP_NAME}` + '&redirect=' + encodeURIComponent(window.location.origin + '/loginsuccessful'),
    tokenManager: {
      storage: 'localStorage',
      autoRenew: true,
      expireEarlySeconds: 200
    }
  };

  return oktaAuthConfig
}

export const AdminAmwayIDOktaConfig = () => {
  const oktaAuthConfig = {
    issuer: `${process.env.REACT_APP_ADMIN_AUTH_ISSUER}`,
    // authorizeUrl: `${process.env.REACT_APP_AUTHORIZE_URL}` +
    //   '?client_id=' + `${process.env.REACT_APP_CLIENT_ID}` +
    //   '&amw_clientapp=' + `${process.env.REACT_APP_CLIENT_APP_NAME}` +
    //   '&cancelRedirect=' + encodeURIComponent(window.location.origin + '/loginsuccessful') +
    //   '&amw_lng=' + languageType,
    //'&scope=' + 'offline_access'+
    //'&z=',
    redirectUri: window.location.origin + '/admin/login/callback',
    clientId: `${process.env.REACT_APP_ADMIN__CLIENT_ID}`,
    pkce: true,
    // scopes: ["openid", "email", "profile", "offline_access"],
    //logoutUrl:`${'https://amway.okta.com/oauth2/default/signout?clientapp=personal-wellness-us&redirect=' + encodeURIComponent(window.location.origin + '/admin/login/callback')}`,
    tokenManager: {
      storage: 'sessionStorage',
      autoRenew: true,
      expireEarlySeconds: 200
    }
  };

  return oktaAuthConfig
}



// logoutUrl: 'https://account-amerreg-qa.amwayglobal.com/signout?clientapp=personal-wellness-us&redirect=' + encodeURIComponent(window.location.origin + '/logout/callback'),
// tokenManager: {
//   storage: 'sessionStorage',
//   autoRenew: true,
//   expireEarlySeconds: 30
// }
//logoutRedirectUri
//postLogoutRedirectUri