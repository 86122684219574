import axios from "axios";
import _ from "lodash";
import { axiosInterceptor } from "../../utils/axiosInterceptor";
import { validateOktaSession } from "../../utils/validateOktaSession";
let qs = require("qs");

axiosInterceptor()
/**
 * API call to fetch accesss token
 */
export function fetchAccessToken() {
  let data = qs.stringify({
    grant_type: "client_credentials",
  });
  let config: any = {
    method: "post",
    url: `${process.env.REACT_APP_ACCESS_TOKEN_URL}`,

    headers: {
      Authorization: `Basic ${process.env.REACT_APP_OKTA_CLIENT_ACCESS_TOKEN}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
    });
}

/**
 * API call to fetch id token for loggedIn User
 */
export async function fetchIdTokenForLogInUser(props?: any) {
  const oktaCred = JSON.parse(
    localStorage.getItem("okta-token-storage") || "[]"
  );
  await validateOktaSession(props);
  let okta_id_token = oktaCred && oktaCred.idToken && oktaCred.idToken.idToken;

  let data = qs.stringify({
    grant_type: "client_credentials",
    assertion: `${okta_id_token}`,
    scope: "openid",
  });
  let config: any = {
    method: "post",
    url: `${process.env.REACT_APP_ACCESS_TOKEN_URL}`,

    headers: {
      Authorization: `Basic ${process.env.REACT_APP_OKTA_CLIENT_ACCESS_TOKEN}`,
    },
    data: data,
  };

  return axios(config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
    });
}

/**
 * API call to fetch id token from anonymous user
 */
export function createAnonymousSession(bool?:any) {

  let config: any = {
    method: "get",
    withCredentials: true,
    url: `${process.env.REACT_APP_ANONYMOUS_SESSION_URL}`,
    headers: {
      CREATE_CART_IF_NOT_EXISTS: "true",
    },
  };

  return axios(config)
    .then((res: any) => {
      if (!localStorage.getItem("okta-token-storage")) {
        localStorage.setItem("cartId", res?.data?.cartId);

        const obj = JSON.parse(
          localStorage.getItem("commerce_token_storage") || "{}"
        );
        let time = Math.round(new Date().getTime() / 1000);
        let tokenData: any = {};
        tokenData.id_token = res?.data?.securityToken?.idToken;
        tokenData.partyId = res?.data?.securityAccount?.partyId;
        tokenData.accountId = res?.data?.securityAccount?.partyId;
        tokenData.expiresAt = res?.data?.securityToken?.expiresIn;
        tokenData.expiryTime =
          time + parseInt(res?.data?.securityToken?.expiresIn);
        obj.idToken = tokenData;
        obj.cart_id = res?.data?.cartId;

        localStorage.setItem("commerce_token_storage", JSON.stringify(obj));
      }
      return res.data;
    })
    .catch((error: any) => {
    });
}

/**
 * this call set commerce tokens in local storage
 */

export async function setAccessToken(props?: any) {
  // let obj: any = {};
  const obj = JSON.parse(
    localStorage.getItem("commerce_token_storage") || "{}"
  );
  let time = Math.round(new Date().getTime() / 1000);

  await fetchAccessToken().then((res: any) => {
    let tokenData: any = {};
    tokenData.access_token = res && res.access_token;
    tokenData.expires_in = res && res.expires_in;
    tokenData.expiryTime = time + parseInt(res && res.expires_in);
    obj.accessToken = tokenData;
    localStorage.setItem("commerce_token_storage", JSON.stringify(obj));
  });

  if (localStorage.getItem("okta-token-storage")) {
    let user_info: any = JSON.parse(localStorage.getItem("user-info") || "{}");
    await fetchIdTokenForLogInUser(props).then((res: any) => {
      let tokenData: any = {};
      tokenData.id_token = res && res.id_token;
      tokenData.partyId = user_info?.partyId;
      tokenData.accountId = user_info?.accountId;
      tokenData.expiresAt = res && res.expires_in;
      tokenData.expiryTime = time + parseInt(res && res.expires_in);
      obj.idToken = tokenData;
      localStorage.setItem("commerce_token_storage", JSON.stringify(obj));
    });
  } else {
    await createAnonymousSession()
  }
}

// this function returns the current language
export function getLanguage() {
  const currentLanguage =
    sessionStorage.getItem("languageTypeOfApp") || "en-us";
    return currentLanguage.replace("-us","_US")

}

/**
 * API call to remove hybris session
 */
 export async function removeHybrisSession(props?: any) {
  var axios = require('axios');

var config = {
  method: 'get',
  withCredentials: true,
  url: `${process.env.REACT_APP_COMMERCE_LOGOUT_URL}`,
  headers: { }
};

axios(config)
.then(function (response:any) {
})
.catch(function (error:any) {
});

}
