/**
 * This utility is used to generate unique Id's
 */

const generateUniqueId = require("generate-unique-id");

const generateUniqueIds: any = () => {
  const id: any = generateUniqueId({
    length: 32,
    useLetters: true,
    useNumbers: true,
  });
  return id;
};

export default generateUniqueIds;

//this function return suggestic user unique id 
export function uniqueUserId() {
  if (
    !localStorage.getItem("okta-token-storage") &&
    !localStorage.getItem("uniqueId")
  ) {
    const GUID = generateUniqueId({
      length: 10,
      useLetters: true,
    });
    localStorage.setItem("uniqueId", GUID);
  }
}
